html {
  scroll-behavior: smooth;
}

.app-icon {
  max-height: 100px;
}

.app-icon-holder {
  background-color: white;
  border: 3px solid #ccc;
  border-radius: 50%;
  height: 190px !important;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-icon-holder.active {
  border: 3px solid #295494;
  height: 190px !important;
  width: 190px;
}

.re-text {
  color: #000;
  font-size: 13px;
  font-weight: 500;
}

.margin-bottom {
  padding-bottom: 20px;
}

.total-days {
  margin-top: 2.5rem !important;
}

.toast-error {
  color: red;
  text-align: left;
  padding-left: 5px;
}

.feature-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
}

.back-to-top {
  right: 0 !important;
  bottom: 20px;
}

.back-to-top-btn {
  width: 60px;
  height: 60px;
  transition: transform 0.2s;
}

.back-to-top-btn:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

.loading-text {
  font-size: 2rem;
}

@media (max-width: 768px) {
  .loading-text {
    font-size: 1.2rem;
  }
}

.feature-width {
  max-width: 349px;
}

.page-wrapper {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image-wrapper {
  display: flex;
  position: relative;
  max-width: 400px;
}

.maintenance-image-wrapper {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maintenance-image-wrapper > img {
  width: 600px;
}

.error-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 175px;
  color: #295494;
}

.error-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  gap: 30px;
}

.error-description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-text-wrapper > h1 {
  font-size: 46px;
  font-weight: 500;
  text-transform: none;
}

.error-description-wrapper > p {
  font-size: 24px;
}

.error-description-wrapper > p,
.error-text-wrapper > h1 {
  text-align: center;
  color: #295494;
}

.navbar {
  display: flex;
  justify-content: space-between;
}

.navlink-wrapper {
  display: flex;
  gap: 70px;
}

.back-button {
  margin-top: 50px;
  font-size: 20px;
  color: #295494;
  font-weight: 500;
  min-height: 56px;
  min-width: 250px;
}

.titles-Appdream {
  background: -webkit-linear-gradient(left, #2f1b3e -60.84%, #295494 111.76%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

.logo-img {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.logo-img:hover {
  opacity: 0.7;
}

.header-font {
  color: #000;
}

.header-font:hover {
  color: #295494;
  text-decoration: underline;
}

.header-scroll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.email-submit-button,
.email-close-button {
  background-color: #295494;
  color: white;
  font-weight: 500;
  width: 250px;
  max-width: 250px;
  text-align: center;
  justify-content: center;
  transition: 0.3s;
}

.email-close-button {
  width: 3rem;
  height: 3rem;
  padding: 0.75em;
  border-radius: 50%;
}

.email-submit-button:hover,
.email-close-button:hover {
  background-color: #295494b4;
}

.email-close-button > .email-close-button-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.email-form-container {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.email-quote-form-wrapper {
  display: flex;
  flex-direction: column;
}

.email-form-wrapper {
  position: relative;
}

.email-quote-form {
  gap: 60px;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.input-wrapper > input {
  height: 60px;
  width: 450px;
  padding: 10px;
  border: 1px solid black;
  color: black;
}

.input-wrapper > select {
  height: 60px;
  width: 450px;
  padding: 10px;
  border: 1px solid black;
  color: #999;
}

.email-quote-form-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.email-quote-form-header-description {
  width: 100%;
  text-align: center;
  font-weight: 700;
  margin-left: 3rem;
}

.icon {
  cursor: pointer;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #999;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 32px;
}

.toast-message-container {
  position: absolute;
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  bottom: 100px;
  z-index: 10;
  transition: fadeIn ease-in-out 1s;
  z-index: 10;
}

.email-success {
  color: #28a745;
  background: #a9dbb4;
  border: 1px solid #28a745;
  padding: 20px 10px;
  font-weight: 600;
  width: 350px;
}

.email-error {
  color: red;
  display: flex;
  justify-content: left;
  padding-left: 1px;
  font-weight: 500;
}

@media only screen and (max-width: 1079px) {
  .email-form-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .email-quote-form {
    gap: 60px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .input-wrapper {
    width: 100%;
  }

  .input-wrapper > input {
    height: 60px;
    width: 100%;
    padding: 10px;
    border: 1px solid black;
    color: black;
  }

  .input-wrapper > select {
    height: 60px;
    width: 100%;
    padding: 10px;
    border: 1px solid black;
    color: #999;
  }

  .email-quote-form-header {
    white-space: pre-wrap;
    align-items: center;
    text-align: initial;
    gap: 20px;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.head-font {
  font-weight: 500;
}

.btn-styles {
  background:
    linear-gradient(to right, #295494 3px, transparent 3px) 0 0,
    linear-gradient(to left, #295494 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, #295494 3px, transparent 3px) 0 0,
    linear-gradient(to top, #295494 3px, transparent 3px) 100% 100%;

  background-size: 18px 18px;
  background-repeat: no-repeat;
  /* -webkit-background-size: 18px 18px; */
  cursor: pointer;
}

.main-container {
  overflow-x: hidden;
}
